<template>

  <v-navigation-drawer class="app--drawer" :clipped="clipped" :mini-variant.sync="mini" app v-model="showDrawer" :width="drawWidth">

    <vue-perfect-scrollbar class="drawer-menu--scroll" :settings="scrollSettings" style="padding-bottom: 20px;">

      <v-list dense expand>

        <template v-for="item in menus">
          <!--Group with Sub Items-->
          <v-list-group v-if="item.items  && item.visibility" :key="item.title" :group="item.group" :prepend-icon="item.icon" no-action="no-action">
            <v-list-tile slot="activator" ripple="ripple">
              <v-list-tile-content>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <template v-for="subItem in item.items">
              <!--sub group-->
              <v-list-group v-if="subItem.items" :key="subItem.name" :group="subItem.group" sub-group="sub-group">
                <v-list-tile slot="activator" ripple="ripple">
                  <v-list-tile-content>
                    <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile v-for="grand in subItem.children" :key="grand.name" :to="genChildTarget(item, grand)" :href="grand.href" ripple="ripple">
                  <v-list-tile-content>
                    <v-list-tile-title>{{ grand.title }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list-group>

              <!--child item-->
              <v-list-tile v-else :key="subItem.name" :to="!subItem.href ? { name: subItem.name } : null" :href="subItem.href" :disabled="subItem.disabled" :target="subItem.target" ripple="ripple">
                <v-list-tile-action v-if="subItem.icon">
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    <span>{{ subItem.title }}</span>
                  </v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action v-if="subItem.action">
                  <v-icon :class="[subItem.actionClass || 'success--text']">{{ subItem.action }}</v-icon>
                </v-list-tile-action>
              </v-list-tile>
              
            </template>

          </v-list-group>

          <v-subheader v-else-if="item.header" :key="item.name">{{ item.header }}</v-subheader>
          <v-divider v-else-if="item.divider" :key="item.name"></v-divider>

          <!--top-level link-->
         <section v-else :key="item.name">
          <v-list-tile v-if="item.visibility" :to="!item.href ? { name: item.name } : null" :href="item.href" ripple="ripple" :disabled="item.disabled" :target="item.target" rel="noopener" :key="item.name">
              <v-list-tile-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action v-if="item.subAction">
                <v-icon class="success--text">{{ item.subAction }}</v-icon>
              </v-list-tile-action>
          </v-list-tile>
         </section>

        </template>
      </v-list>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>
<script>
import menu from "@/util/menu.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "AppDrawer",
  components: {
    VuePerfectScrollbar
  },
  props: {

    expanded: { type: Boolean, default: true },
    drawWidth: { type: [Number, String], default: "260" },
    showDrawer: Boolean

  },
  data() {
    return {

      mini            : false,
      clipped         : true,
      menus           : menu,
      scrollSettings  : { maxScrollbarLength: 160 },
      times           : 0

    };
  },
  computed: {
    computeGroupActive() {
      return true;
    },
    computeLogo() {
      return "/static/m.png";
    },
    sideToolbarColor() {
      return this.$vuetify.options.extra.sideNav;
    },
    // GetAccessTabs(){

    //   this.menus.forEach((element,index) => {
    //     let indexs = this.$store.getters.GetAccessTabs.findIndex(x => x == element['name'])
    //     if(indexs > -1){
    //       this.menus[index]['visibility'] = true;
    //     }else{
    //       this.menus[index]['visibility'] = false;
    //     }
    //   });

    //   return this.$store.getters.GetAccessTabs
      
    // },
    propShow(){
      return this.times
    }
  },
  watch: { 
    // GetAccessTabs: function(Tabs, oldTabs) {
    //     this.menus.forEach((element,index) => {
    //     let indexs = Tabs.findIndex(x => x == element['name'])
    //     if(indexs > -1){
    //       this.menus[index]['visibility'] = true;
    //     }else{
    //       this.menus[index]['visibility'] = false;
    //     }
    //   });
    // }
  },
  methods: {
    genChildTarget(item, subItem) {
      if (subItem.href) return;
      if (subItem.component) {
        return {
          name: subItem.component
        };
      }
      return { name: `${item.group}/${subItem.name}` };
    }
  },
  mounted(){

  }
};
</script>

<style lang="stylus" scoped>
.app--drawer {
  overflow: hidden;

  .drawer-menu--scroll {
    height: calc(100vh - 48px);
    overflow: auto;
  }
}
</style>
