<template>
  <v-app>
    <div class="app-root">
      <app-loading :dialog="Loading"></app-loading>
      <section>
        <v-navigation-drawer class="setting-drawer" temporary right v-model="rightDrawer" hide-overlay fixed></v-navigation-drawer>
        <router-view></router-view>
      </section>
      <app-snack-bar></app-snack-bar>

    </div>
  </v-app>
</template>

<script>
import appLoading from "@/components/Loader";
import appSnackBar from "@/components/Snackbar";

export default {
  components: {
    appLoading,
    appSnackBar
  },
  data() {
    return {
      rightDrawer: false
    };
  },
  created() {
      this.$eventHub.$on('LOADING', this.SetLoading);
  },
  beforeDestroy() {
      this.$eventHub.$off('LOADING');
  },
  computed: {
    Loading() {
      return this.$store.getters.GetLoading;
    }
  },
  methods:{
    SetLoading(payload){
      this.$store.commit("SetLoading",payload == 'true' ? true : false)
    }
  },
  mounted(){

  }
};
</script>

<style>
.box-shadow{
    -webkit-box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
}
.setting-fab {
  top: 50% !important;
  right: 0;
  border-radius: 0;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.daterangepicker div:first-child{
    display: inline-flex !important;
}
</style>
