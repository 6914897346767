<template>
    <v-toolbar color="primary" fixed dark app :clipped-left="clipped">

        <v-toolbar-title>
          <v-toolbar-side-icon @click="handleDrawerToggle" v-if="logout"></v-toolbar-side-icon>
        </v-toolbar-title>
        <div class="d-flex align-center">
                <v-img alt="Examin" class="shrink mr-2 ml-2" contain :src="Logo" width="160" />
          </div>
        <v-spacer></v-spacer>
        <v-toolbar-items>
            <v-btn icon large flat slot="activator" v-if="logout">
              <v-avatar size="30px">
                <v-icon @click="handleLogout">logout</v-icon>
              </v-avatar>
            </v-btn>
            <v-btn large flat slot="activator" v-if="!logout" @click="loginHere">Login</v-btn>
        </v-toolbar-items>
    </v-toolbar>
</template>
<script>

import Util from "@/util"
import appLogo from '@/assets/logo.png'

export default {
  props: [ 'logout'],
  data() {
    return {
        clipped  : true,
        NotyCount : 0
    }
  },
  computed:{
     Logo(){
            return appLogo
    },
  },
  methods: {
    loginHere(){

      // this.$router.push('/login');
      window.location.href = `https://exam.examin.online/`;
      
    },
    RedirectToHome(){
       
        this.$router.push('/')
        this.$router.go()
    },
    SetCount(Count){
        this.NotyCount = Count
    },
    handleDrawerToggle() {
      this.$emit("side-icon-click")
    },
    handleFullScreen() {
      Util.toggleFullScreen()
    },
    handleLogout() {
            
        document.cookie = 'token' + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie = 'user_type' + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        localStorage.removeItem('token')
        localStorage.removeItem('user_type')
        this.$router.push('/login')
        this.$router.go()

    }
  }
}
</script>

<style scoped>
.activeClass{
  background: #d3d3d35c !important;
}
</style>
