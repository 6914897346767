import Vue from "vue"
import Router from "vue-router"
import { publicRoute } from "./config"
const routes = publicRoute
import store  from '../store'

Vue.use(Router)
const router = new Router({
  mode: process.env.IS_ELECTRON ? "hash" : 'history',
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes: routes
})

// Router Guards

router.beforeEach((to, from, next) => {

    store.commit("SetLoading",true)
    let token     = process.env.IS_ELECTRON ? GetCookieStorage('token') : GetCookie('token');
    let user_type = process.env.IS_ELECTRON ? GetCookieStorage('user_type') : GetCookie('user_type');

    if(to.matched.some(record => record.meta.child == 'admin')){ 

        if(to.matched.some(record => record.meta.auth_required)){
          console.log(user_type + '_' + token)
          user_type === 'admin' && token ? next() : next(process.env.VUE_APP_BUILD_TYPE == 'Web' ? '/home'  : '/server')

        }else{

          !token ? next() : next('/')
        }

    }else if(to.matched.some(record => record.meta.child == 'user')){

        if(to.matched.some(record => record.meta.auth_required)){

          user_type !== 'admin' && token ? next() : next(process.env.VUE_APP_BUILD_TYPE == 'Web' ? '/home'  : '/server')

        }else{

          !token ?  next() : next('/supervise')
        }

    }else{

        next();
    }
})


function GetCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

function GetCookieStorage(cname) {

  return localStorage.getItem(cname) ? localStorage.getItem(cname) : "";

 }

export default router
