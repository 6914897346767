<template>
  <v-card class="elevation-1 pa-3 login-card">
    <v-card-text>
      <div class="layout column align-center">
        <h1 class="flex my-4 primary--text">Admin Login</h1>
      </div>
      <v-form>
        <v-text-field append-icon="email" name="Email" label="Email" v-model="Email"></v-text-field>
        <v-text-field append-icon="lock" name="Password" label="Password" type="password" v-model="Password"></v-text-field>
        <v-text-field append-icon="person" name="Center Code" label="Center Code" v-model="Center_Code"></v-text-field>
      </v-form>
    </v-card-text>
    <div class="login-btn">
      <v-spacer></v-spacer>
      <v-btn block color="primary" @click="Login">Login</v-btn>
    </div>
    <div style="text-align: center;padding: 12px;">
      <a target="_blank" href="https://webvue.co/admin">Click here for Superviser Login</a>
    </div>
  </v-card>
</template>

<script>
export default {
  data(){
    return {

        loading      : false,
        Email        : "",
        Password     : "",
        Center_Code  : ""

      }
  },
  methods: {

    Login() {
      
        let data = { 'email': this.Email, 'password': this.Password, 'center_id': parseInt(this.Center_Code) }
        this.$http.post(`${this.$store.getters.GetProductionUrl}/admin/login`,data)
            .then((response) => {
                 
                let Response = response && response.data && response.data.response ? response.data.response : {};

                if(Object.keys(response['data']['session']).length > 0){

                  this.$store.commit('setAdmin', Response)
                  this.$store.commit('setLoginStatus', true)
                  var d = new Date(response['data']['session']['expires_at'])
                  document.cookie = `token=${response['data']['session']['token']};expires=${d.toUTCString()}`
                  document.cookie = `user_type=${response['data']['session']['user_type']};expires=${d.toUTCString()}`
                  localStorage.setItem('token', response['data']['session']['token'])
                  localStorage.setItem('user_type', response['data']['session']['user_type'])
                  this.$http.defaults.headers.common['Authorization'] = response['data']['session']['token']
                  process.env.VUE_APP_BUILD_TYPE != 'Web' ? this.$Socket.SetEnableBackup() : {}
                  this.$router.push('/dashboard')

                }else{

                  console.log(Response)

                }

            }).catch((err) => {

                console.log(err)              
            });
           
    }
  },
  mounted(){
    
    this.$eventHub.$emit("LOADING",'false')
  }
}
</script>
<style scoped lang="css"></style>
