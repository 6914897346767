<template>
  <v-app id="inspire" class="app dashboard">
    <app-drawer class="app--drawer" :show-drawer="showDrawer"></app-drawer>
    <app-toolbar class="app--toolbar" @side-icon-click="handleDrawerVisiable" :logout='true'></app-toolbar>
    <v-content>
      <!-- Page Header -->
      <!-- <page-header></page-header> -->
      <!-- <v-divider></v-divider>  -->
      <div class="page-wrapper">

        <router-view></router-view>
              
      </div>
      <!-- App Footer -->
      <!-- <v-footer height="auto" class="white pa-3 app--footer">
        <span class="caption">Site Galleria Pvt Ltd  &copy; {{ new Date().getFullYear() }}</span>
        <v-spacer></v-spacer>
      </v-footer> -->
    </v-content>
    <!-- Go to top -->
    <app-fab></app-fab>
  </v-app>
</template>

<script>
import AppDrawer from "@/components/AppDrawer"
import AppToolbar from "@/components/AppToolbar"
import AppFab from "@/components/AppFab"
import PageHeader from "@/components/PageHeader"

export default {
  components: {
    AppDrawer,
    AppToolbar,
    AppFab,
    PageHeader
  },

  data() {
    return {
      showDrawer: true
    }
  },
  methods: {
    handleDrawerVisiable() {
      this.showDrawer = !this.showDrawer
    }
  },
  created() {}
}
</script>

<style scoped>
.page-wrapper {
  /* calc(100vh - 64px - 50px - 81px); */
  min-height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
}
</style>
